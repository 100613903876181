import { useSearch } from '@tanstack/react-router'
import { memo, useCallback, useReducer } from 'react'

import { css } from '@emotion/react'

import {
  Button,
  CheckboxContainer,
  PersistentNotification,
  Text,
  TextInputLabelWrapper,
  ValidatedTextField,
  spacing,
  useAsyncCallback,
  useScreenType,
} from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { trpc } from '../../../client'
import { useNavigateBack } from '../../../lib/hooks/use-navigate-back'
import { GoBackButton } from './components/go-back-button'
import { SubscriptionStepHeaderLayout, SubscriptionStepLayout } from './components/subscription-step-layout'
import { subscriptionStepOuterElementCss } from './components/subscription-step-outer-element-style'
import { SubscriptionV2Header } from './components/subscription-v2-header'
import { mappers } from './elements/string-element-mappers'
import { SubscriptionPageV2 } from './subscription-v2-page'

const initialFormState = {
  email: '',
  phone: '',
  hasConsented: false,
  isSent: false,
}

type ReducerAction =
  | {
      type: 'phone_change' | 'email_change'
      value: string | undefined
    }
  | {
      type: 'set_consent'
      value: boolean | undefined
    }
  | {
      type: 'sent'
    }

const formReducer = (state: Partial<typeof initialFormState>, action: ReducerAction) => {
  switch (action.type) {
    case 'phone_change':
      return {
        ...state,
        phone: action.value,
      }
    case 'email_change':
      return {
        ...state,
        email: action.value,
      }
    case 'set_consent':
      return {
        ...state,
        hasConsented: action.value,
      }
    case 'sent':
      return {
        ...state,
        isSent: true,
      }
  }
}

const SubscriptionExclusionPageV2 = memo(function SubscriptionExclusionPageV2() {
  const navigateBack = useNavigateBack()
  const screenType = useScreenType()

  const { subscriptionId } = useParams({ from: '/subscribe-exclusion/$subscriptionId' })
  const searchParams = useSearch({ strict: false })
  const description = searchParams.description
  const stepId = searchParams.stepId

  const [state, dispatch] = useReducer(formReducer, initialFormState)

  const emailChangeHandler = useCallback(
    (value: string | undefined) => dispatch({ type: 'email_change', value }),
    [dispatch],
  )

  const phoneChangeHandler = useCallback(
    (value: string | undefined) => dispatch({ type: 'phone_change', value }),
    [dispatch],
  )

  const consentChangeHandler = useCallback(
    (value: boolean | undefined) => dispatch({ type: 'set_consent', value }),
    [dispatch],
  )

  const onSubmit = useAsyncCallback(async () => {
    if (!state.phone || !state.email) return

    await trpc.subscriptions.updateContactInformationWithoutErrorManagement.mutate({
      subscriptionId,
      phone: state.phone,
      email: state.email,
    })
    dispatch({ type: 'sent' })
  }, [state, subscriptionId])

  const isReadyToSend = state.phone && state.email && state.hasConsented

  return (
    <SubscriptionPageV2>
      <div css={subscriptionStepOuterElementCss}>
        <SubscriptionStepHeaderLayout progress={1} compact={false}>
          <SubscriptionV2Header />
        </SubscriptionStepHeaderLayout>
        <SubscriptionStepLayout
          left={<GoBackButton goBackToPreviousStep={navigateBack} />}
          right={<></>}
          compact={false}
          center={
            <div>
              <Text variant="h5">Nous ne pouvons pas encore vous assurer.</Text>
              <Text
                css={css`
                  margin-top: ${spacing[70]};
                `}
                variant="body2"
              >
                {description
                  ? description
                  : `
                      Il semblerait que nous ne puissions pas encore couvrir votre activité.
                      D’ailleurs, pensez à vérifier si une erreur ne se serait pas glissée dans vos déclaration.
                    `}
              </Text>
              <Text
                css={css`
                  margin-top: ${spacing[70]};
                  margin-bottom: ${spacing[70]};
                `}
                variant="body2"
              >
                Chaque jour, nous élargissons notre couverture et protégeons de nouvelles activités. N’hésitez pas à
                nous laisser vos coordonnées pour que nos conseillers puissent vous recontacter le cas échéant.
              </Text>

              {stepId !== 'post-quote-rcda-revenue-data' &&
                (!state.isSent ? (
                  <>
                    <TextInputLabelWrapper
                      css={css`
                        margin-top: ${spacing[70]};
                      `}
                      label="Email"
                    >
                      <ValidatedTextField
                        size="large"
                        placeholder="jean.dupont@mail.com"
                        mapper={mappers.email}
                        value={state.email}
                        onChange={emailChangeHandler}
                      />
                    </TextInputLabelWrapper>
                    <TextInputLabelWrapper
                      css={css`
                        margin-top: ${spacing[50]};
                        margin-bottom: ${spacing[70]};
                      `}
                      label="Numéro de téléphone"
                    >
                      <ValidatedTextField
                        size="large"
                        placeholder="06 00 00 00 00"
                        mapper={mappers.phone}
                        value={state.phone}
                        onChange={phoneChangeHandler}
                      />
                    </TextInputLabelWrapper>
                    <CheckboxContainer checked={!!state.hasConsented} onChange={consentChangeHandler} border={true}>
                      <Text variant="body2">
                        J’accepte les{' '}
                        <a href="https://www.orus.eu/conditions-generales-dutilisation">
                          conditions générales d’utilisation
                        </a>{' '}
                        et la{' '}
                        <a href="https://www.orus.eu/politique-confidentialite">
                          politique d’utilisation de mes données
                        </a>
                        .
                      </Text>
                    </CheckboxContainer>
                    <div
                      css={css`
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                      `}
                    >
                      <Button
                        type="submit"
                        css={css`
                          margin-top: ${spacing[70]};
                        `}
                        variant="primary"
                        size={screenType === 'desktop' ? 'medium' : 'large'}
                        onClick={onSubmit}
                        disabled={!isReadyToSend}
                        trackingId="contact_button"
                      >
                        Envoyer
                      </Button>
                    </div>
                  </>
                ) : (
                  <PersistentNotification variant="success" title="Coordonnées confirmées">
                    <Text variant="body2">
                      Notre équipe vous contactera au plus vite par email à {state.email} ou par téléphone au{' '}
                      {state.phone}.
                    </Text>
                  </PersistentNotification>
                ))}
            </div>
          }
        />
      </div>
    </SubscriptionPageV2>
  )
})

export default SubscriptionExclusionPageV2
